<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        depressed
        rounded
        color="primary"
        style="float: right"
        v-bind="attrs"
        v-on="on"
        >Add proxy</v-btn
      >
    </template>
    <v-card>
      <v-form @submit.prevent="saveProxy" ref="proxyForm">
        <v-row no-gutters class="my-0 py-0">
          <v-spacer></v-spacer
          ><v-card-actions>
            <v-btn icon @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
        </v-row>
        <v-card-title>
          <v-row class="mx-2">Add proxy</v-row>
        </v-card-title>
        <v-card-text>
          <v-alert text color="red" type="error" v-if="errorMsg">
            {{ errorMsg }}
          </v-alert>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="newItem.address"
                  :rules="[inputRequired]"
                  label="Address"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  v-model="newItem.login"
                  :rules="[inputRequired]"
                  label="Login"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  v-model="newItem.password"
                  :rules="[inputRequired]"
                  label="Password"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" sm="3" md="3">
                <v-select
                  v-model="newItem.isActive"
                  item-value="attribute"
                  item-text="label"
                  :items="boolItems"
                  :rules="[inputRequired]"
                  label="Enabled"
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="close"> Cancel </v-btn>
          <v-btn color="primary" text @click="saveProxy"> Save </v-btn>
          <v-btn color="primary" text @click="saveProxyGo"> Save & Go </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import { EventBus } from "@/utils";
import { NEW_CREATED_PROXY } from "@/utils/events";
import { CREATE_PROXY } from "@/store/modules/proxy/actions";

export default {
  name: "ProxyAddDialog",
  props: {
    packageId: {
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      itemsLength: [],
      items: [],
      newItem: {
        address: null,
        login: null,
        password: null,
        isActive: 1,
      },
      defaultItem: {
        address: null,
        login: null,
        password: null,
        isActive: 1,
      },
      errorMsg: "",
      boolItems: [
        {
          attribute: 1,
          label: "Yes",
        },
        {
          attribute: 0,
          label: "No",
        },
      ],
      inputRequired: (v) => !!v || v === 0 || "Required field",
      requiredRule: (v) => !!v || "Not enough characters to save proxy",
      minCharsRule: (v) => {
        if (!(v instanceof Array)) {
          return (v && v.length >= 2) || "Input too short!";
        } else {
          const tooShortItems = v.filter(function (item) {
            return item.length < 2;
          });
          return tooShortItems.length === 0 || "Input too short!";
        }
      },
      maxCharsRule: (v) => {
        if (!(v instanceof Array)) {
          return (v && v.length <= 30) || "Input too long!";
        } else {
          const tooLongItems = v.filter(function (item) {
            return item.length > 30;
          });
          return tooLongItems.length === 0 || "Input too long!";
        }
      },
    };
  },
  watch: {
    dialog(val) {
      this.errorMsg = null;
      val || this.close();
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.errorMsg = null;
      this.$refs.proxyForm.resetValidation();
      this.newItem = Object.assign({}, this.defaultItem);
    },
    saveProxyGo() {
      let vm = this;
      return this.createProxy(function (data) {
        vm.errorMsg = null;
        vm.$refs.proxyForm.resetValidation();
        vm.newItem = Object.assign({}, vm.defaultItem);
        EventBus.$emit(NEW_CREATED_PROXY, data.id);
      });
    },
    saveProxy() {
      let vm = this;
      return this.createProxy(function (data) {
        vm.close();
        EventBus.$emit(NEW_CREATED_PROXY, data.id);
      });
    },
    createProxy(resolve) {
      let vm = this;
      if (!vm.$refs.proxyForm.validate()) {
        this.errorMsg = "Please check all fields!";
      } else {
        let payload = _.merge({ packageId: this.packageId }, this.newItem);
        this.$store
          .dispatch(`proxy/${CREATE_PROXY}`, payload)
          .then(resolve, function (error) {
            vm.errorMsg = error;
          })
          .catch(() => {
            this.errorMsg = "Something went wrong";
          });
      }
    },
  },
};
</script>
