<template>
  <div>
    <div>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-actions class="py-0">
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDelete">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
          <v-card-title
            >Are you sure you want to delete this item?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" text @click="closeDelete">Cancel</v-btn>
            <v-btn color="primary" text @click="deleteItemConfirm">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div>
      <v-dialog v-model="editDialog" max-width="500px">
        <v-card>
          <v-form @submit.prevent="putEditItem" ref="proxyForm">
            <v-row no-gutters class="my-0 py-0">
              <v-spacer></v-spacer
              ><v-card-actions>
                <v-btn icon @click="clearEditDialog">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-actions>
            </v-row>
            <v-card-title>
              <v-row class="mx-2">Edit {{ editedItem.address }}</v-row>
            </v-card-title>
            <v-card-text>
              <v-alert text color="red" type="error" v-if="errorMsg">
                {{ errorMsg }}
              </v-alert>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem.address"
                      :rules="[inputRequired]"
                      label="Address"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.login"
                      :rules="[inputRequired]"
                      label="Login"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.password"
                      :rules="[inputRequired]"
                      label="Password"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3" sm="3" md="3">
                    <v-select
                      v-model="editedItem.isActive"
                      item-value="attribute"
                      item-text="label"
                      :items="boolItems"
                      :rules="[inputRequired]"
                      label="Enabled"
                      required
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey darken-1" text @click="clearEditDialog">
                Cancel
              </v-btn>
              <v-btn color="primary" text @click="putEditItem"> Save </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </div>
    <h1 v-if="packageItem" class="outline--text">
      {{ this.packageItem.name }}
      <proxy-import-dialog
        v-if="canManage"
        :packageId="id"
      ></proxy-import-dialog>
      <proxy-add-dialog v-if="canManage" :packageId="id"></proxy-add-dialog>
    </h1>
    <v-row class="mt-4">
      <v-btn
        class="my-0 py-0"
        color="primary"
        depressed
        text
        small
        tile
        plain
        @click="backToPackages"
      >
        <v-icon>mdi-arrow-left-thin</v-icon>Back to proxy packages
      </v-btn>
    </v-row>
    <proxy-search></proxy-search>
    <v-row>
      <v-col class="outline--text mt-7" style="max-width: 700px">
        <div>
          <span v-if="!isLoading && this.totalCount > 0">
            <p v-if="this.totalCount > this.currentPage * this.itemsPerPage">
              Showing {{ (this.currentPage - 1) * this.itemsPerPage + 1 }}-{{
                this.currentPage * this.itemsPerPage
              }}
              of {{ this.totalCount }} items
            </p>
            <p v-else>
              Showing {{ (this.currentPage - 1) * this.itemsPerPage + 1 }}-{{
                this.totalCount
              }}
              of {{ this.totalCount }} items
            </p>
          </span>
        </div>
      </v-col>
      <v-col>
        <div class="d-flex justify-space-between">
          <v-spacer></v-spacer>
          <v-pagination
            v-if="showPages && pageCount > 1"
            class="my-3"
            v-model="pagination.page"
            :length="pageCount"
            :total-visible="7"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
          ></v-pagination>
          <div class="d-flex mx-10">
            <v-select
              v-model="pagination.rowPerPage"
              item-text="label"
              item-value="attribute"
              :items="countPerPage"
              label="Show items"
              style="max-width: 80px"
            >
            </v-select>
          </div>
          <div class="d-flex">
            <v-select
              item-text="label"
              item-value="attribute"
              :items="sortAttributes"
              label="sortBy"
              v-model="sort.sortBy"
              style="max-width: 180px"
            ></v-select>
            <span v-if="sort.sortBy" class="mt-5 ml-2">
              <v-icon
                v-if="sort.desc"
                title="sortAsc"
                @click="sort.desc = false"
              >
                mdi-sort-descending
              </v-icon>
              <v-icon v-else title="sortDesc" @click="sort.desc = true">
                mdi-sort-ascending
              </v-icon>
            </span>
            <v-btn
              depressed
              rounded
              small
              outlined
              icon
              color="grey darken-1"
              style="float: right"
              class="my-5 mx-6"
              @click="printItems"
              ><v-icon> mdi-printer-outline </v-icon></v-btn
            >
            <v-btn
              depressed
              rounded
              small
              outlined
              icon
              color="grey darken-1"
              style="float: right"
              class="my-5"
              @click="exportItems"
              ><v-icon> mdi-file-export-outline </v-icon></v-btn
            >
          </div>
        </div>
      </v-col>
    </v-row>
    <v-data-table
      id="itemsTable"
      :headers="headers"
      :loading="isLoading"
      :items="proxies"
      :items-per-page="5"
      disable-pagination
      disable-filtering
      disable-sort
      hide-default-footer
      :item-class="itemClass"
    >
      <template v-slot:item.isActive="{ item }">
        <v-switch
          class="pa-0 px-0 mx-0"
          dense
          color="#67bb6a"
          v-model="item.isActive"
          :label="item.id.toString()"
          @click="editStatus(item)"
          :disabled="!canManage"
        >
        </v-switch>
      </template>
      <template v-slot:item.status="{ item }">
        <v-row>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <img
                  v-if="item.status === 'incorrect'"
                  class="mx-2"
                  :src="require('@/assets/alert-box-outline.png')"
                  alt=""
                />
                <img
                  v-else-if="item.status === 'correct'"
                  class="mx-2"
                  :src="require('@/assets/check-circle-outline.png')"
                  alt=""
                />
                <img
                  v-else
                  class="mx-2"
                  :src="require('@/assets/broadcast-off.png')"
                  alt=""
                />
              </span>
            </template>
            <p v-if="item.status === 'incorrect'" class="my-2">Incorrect</p>
            <p v-else-if="item.status === 'correct'" class="my-2">Correct</p>
            <p v-else class="my-2">No data</p>
          </v-tooltip>
          <span v-if="item.status === 'incorrect'" class="red--text mt-1">
            Incorrect
          </span>
          <span v-else-if="item.status === 'correct'" class="green--text mt-1">
            Correct
          </span>
          <span v-else class="grey--text mt-1">NA</span>
        </v-row>
      </template>
      <template v-slot:item.Actions="{ item }">
        <v-icon v-if="canManage" class="mr-2" @click="editItem(item)">
          mdi-pencil-outline
        </v-icon>
        <v-icon v-if="canManage" color="red" @click="deleteItem(item)">
          mdi-delete-forever-outline
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import _ from "lodash";
import { NEW_CREATED_PROXY, NEW_IMPORTED_PROXIES } from "@/utils/events";
import { EventBus } from "@/utils";
import { mapGetters, mapState } from "vuex";
import BaseListPage from "./base/BaseListPage";
import ProxySearch from "@/views/proxy/list/ProxySearch";
import ProxyAddDialog from "@/views/proxy/item/ProxyAddDialog";
import ProxyImportDialog from "@/views/proxy/item/ProxyImportDialog";
import {
  defaultFilters,
  emptyFilters,
  multipleFilters,
} from "@/store/modules/proxy/state";
import {
  DELETE_PROXY,
  FETCH_PROXIES,
  FETCH_PROXIES_FILE,
  FETCH_PROXY,
  SET_FILTERS,
  SET_PROXY_PACKAGE_ID,
  UPDATE_PROXY,
} from "@/store/modules/proxy/actions";
import { FETCH_PROXY_PACKAGE } from "@/store/modules/proxyPackage/actions";
import { SET_EMPTY_FILTERS } from "@/store/modules/tag/actions";

import acl from "@/mixin/acl";
import { RESOURCE_PROXIES_MANAGEMENT } from "@/store/modules/admin/getters";
export default {
  name: "ProxyPage",
  mixins: [acl],
  extends: BaseListPage,
  components: { ProxySearch, ProxyAddDialog, ProxyImportDialog },
  props: {
    id: {
      required: true,
    },
    limit: {
      required: false,
    },
  },
  inheritAttrs: true,
  data() {
    return {
      defaultFilters,
      emptyFilters,
      multipleFilters,
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      dialogDelete: false,
      showPages: false,
      errorMsg: "",
      boolItems: [
        {
          attribute: 1,
          label: "Yes",
        },
        {
          attribute: 0,
          label: "No",
        },
      ],
      inputRequired: (v) => !!v || v === 0 || "Required field",
      requiredRule: (v) => !!v || "Not enough characters to save proxy",
      minCharsRule: (v) => {
        if (!(v instanceof Array)) {
          return (v && v.length >= 2) || "Input too short!";
        } else {
          const tooShortItems = v.filter(function (item) {
            return item.length < 2;
          });
          return tooShortItems.length === 0 || "Input too short!";
        }
      },
      maxCharsRule: (v) => {
        if (!(v instanceof Array)) {
          return (v && v.length <= 30) || "Input too long!";
        } else {
          const tooLongItems = v.filter(function (item) {
            return item.length > 30;
          });
          return tooLongItems.length === 0 || "Input too long!";
        }
      },
      countPerPage: [
        {
          attribute: 20,
          label: "20",
        },
        {
          attribute: 50,
          label: "50",
        },
        {
          attribute: 100,
          label: "100",
        },
        {
          attribute: 5000,
          label: "All",
        },
      ],
      headers: [
        {
          text: "",
          value: "isActive",
          width: "75",
        },
        {
          text: "Proxy Address",
          value: "address",
          width: "300",
        },
        {
          text: "Proxy Login",
          value: "login",
          width: "150",
        },
        {
          text: "Proxy Password",
          value: "password",
          width: "150",
        },
        {
          text: "Proxy Errors",
          value: "proxyErrors",
          width: "100",
          align: "center",
        },
        {
          text: "Channels Error",
          value: "errorChannelsCount",
          width: "100",
          align: "center",
        },
        {
          text: "Last Check Status",
          value: "status",
          width: "150",
          align: "center",
        },
        {
          text: "Last Check",
          value: "lastCheck",
          width: "200",
        },
        {
          text: "Next Check",
          value: "nextCheck",
          width: "200",
        },
        {
          width: "125",
          text: "",
          value: "Actions",
          align: "center",
        },
      ],
      sortAttributes: [
        {
          attribute: "id",
          label: "ID",
        },
        {
          attribute: "proxyErrors",
          label: "Proxy Errors",
        },
        {
          attribute: "errorChannelsCount",
          label: "Channels Error",
        },
        {
          attribute: "lastCheckStatus",
          label: "Last Check Status",
        },
        {
          attribute: "lastCheck",
          label: "Last Check",
        },
        {
          attribute: "active",
          label: "Enabled",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("proxy", [
      "proxyList",
      "totalCount",
      "currentPage",
      "pageCount",
      "offset",
      "formattedFilters",
    ]),
    ...mapGetters("proxyPackage", ["packageItem"]),
    ...mapState("proxy", ["sort", "pagination", "filters"]),
    canManage: function () {
      return this.hasAccess([RESOURCE_PROXIES_MANAGEMENT]);
    },
    itemsPerPage: function () {
      return this.pagination.rowPerPage;
    },
    proxies: function () {
      if (this.isLoading) {
        return [];
      }
      return this.proxyList;
    },
  },
  watch: {
    "pagination.rowPerPage": function () {
      this.pagination.page = 1;
    },
  },
  methods: {
    backToPackages() {
      if (this.prevRoute) {
        this.$router.push(this.prevRoute);
      } else {
        this.$router.go(-1);
      }
    },
    clearEditDialog() {
      this.errorMsg = "";
      this.closeEditDialog();
    },
    applyFilters: function (payload) {
      return this.$store.dispatch(`proxy/${SET_FILTERS}`, payload.data);
    },
    fetchItems: function () {
      const vm = this;
      vm.isLoading = true;
      let packageId = vm.id;
      vm.$store.dispatch(`proxy/${FETCH_PROXIES}`, { packageId }).then(
        () => {
          vm.isLoading = false;
          vm.showPages = true;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    fetchItem: function (itemId, how) {
      const vm = this;
      vm.$store.dispatch(`proxy/${FETCH_PROXY}`, { itemId, how }).then(
        () => {},
        (error) => {
          console.log(error);
        }
      );
    },
    editItem: function (item) {
      this.editedItem = _.merge({}, item);
      this.editDialog = true;
    },
    editStatus(item) {
      this.editedItem = _.merge({}, item);
      this.putEditItem();
    },
    putEditItem() {
      if (this.editDialog) {
        if (!this.$refs.proxyForm.validate()) {
          this.errorMsg = "Please check all fields!";
          return;
        }
      }
      const vm = this;
      let { editedItem } = this;
      let { id } = editedItem;
      vm.$store
        .dispatch(`proxy/${UPDATE_PROXY}`, {
          id,
          editedItem,
        })
        .then(
          () => {
            vm.fetchItem(this.editedItem.id);
            if (this.editDialog) {
              this.clearEditDialog();
            }
          },
          (error) => {
            this.errorMsg = error;
          }
        );
    },
    closeDelete() {
      this.dialogDelete = false;
      this.selectedItem = null;
    },
    deleteItem(item) {
      this.selectedItem = item;
      this.dialogDelete = true;
    },
    deleteItemConfirm: function () {
      let vm = this;
      vm.$store
        .dispatch(`proxy/${DELETE_PROXY}`, vm.selectedItem)
        .then(
          function () {
            if (vm.proxyPackages.length === 0) {
              vm.$store.dispatch(`proxy/${SET_EMPTY_FILTERS}`);
              vm.fetchItems();
            }
          },
          function (error) {
            vm.errorMsg = error;
          }
        )
        .catch(() => {
          vm.errorMsg = "Something went wrong";
        });
      this.dialogDelete = false;
    },
    exportItems: function () {
      const vm = this;
      let packageId = vm.id;
      vm.$store.dispatch(`proxy/${FETCH_PROXIES_FILE}`, { packageId }).then(
        () => {},
        (error) => {
          console.log(error);
        }
      );
    },
    printItems: function () {
      this.$htmlToPaper("itemsTable", null, () => {});
    },
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === "ProxyPackageListPage") {
      next((vm) => {
        vm.prevRoute = from;
      });
    } else {
      next();
    }
  },
  mounted() {
    const vm = this;
    vm.$store.dispatch(`proxy/${SET_PROXY_PACKAGE_ID}`, vm.id);
    vm.$store.dispatch(`proxyPackage/${FETCH_PROXY_PACKAGE}`, {
      itemId: vm.id,
    });
    vm.applyRouteParams();
    vm.fetchItems();
  },
  created() {
    EventBus.$on(NEW_IMPORTED_PROXIES, () => {
      this.fetchItems();
    });
    EventBus.$on(NEW_CREATED_PROXY, (id) => {
      this.fetchItem(id, "push");
    });
  },
};
</script>
