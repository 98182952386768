<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        depressed
        rounded
        color="primary"
        style="float: right"
        v-bind="attrs"
        v-on="on"
        class="mx-4"
        >Import proxy</v-btn
      >
    </template>
    <v-card>
      <v-form @submit.prevent="importProxy" ref="proxyImportForm">
        <v-row no-gutters class="my-0 py-0">
          <v-spacer></v-spacer
          ><v-card-actions>
            <v-btn icon @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
        </v-row>
        <v-card-title>
          <v-row class="mx-2">Import proxy</v-row>
        </v-card-title>
        <v-card-text>
          <v-alert text color="red" type="error" v-if="errorMsg">
            {{ errorMsg }}
          </v-alert>
          <v-container>
            <v-row class="mt-4">
              <v-btn color="green" text @click="downloadTemplate">
                <v-icon class="mr-2">mdi-file-download-outline</v-icon>Download
                template</v-btn
              >
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-file-input
                  v-model="file"
                  class="ml-1"
                  show-size
                  :rules="[inputRequired, maxFileSize]"
                  prepend-icon="mdi-file-upload-outline"
                  label="Choose file to upload (.xlsx)"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="close"> Cancel </v-btn>
          <v-btn color="primary" text @click="importProxy"> Save </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from "@/utils";
import { NEW_IMPORTED_PROXIES } from "@/utils/events";
import {
  FETCH_PROXY_TEMPLATE,
  IMPORT_PROXY_FILE,
} from "@/store/modules/proxy/actions";

export default {
  name: "ProxyImportDialog",
  props: {
    packageId: {
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      file: null,
      errorMsg: "",
      inputRequired: (v) => !!v || v === 0 || "Required field",
      maxFileSize: (v) =>
        !v || v.size < 2000000 || "File size should be less than 2 MB",
    };
  },
  watch: {
    dialog(val) {
      this.errorMsg = null;
      val || this.close();
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.errorMsg = null;
      this.$refs.proxyImportForm.resetValidation();
      this.$refs.proxyImportForm.reset();
      this.newItem = Object.assign({}, this.defaultItem);
    },
    downloadTemplate() {
      this.$store.dispatch(`proxy/${FETCH_PROXY_TEMPLATE}`);
    },
    importProxy() {
      let vm = this;
      if (!vm.$refs.proxyImportForm.validate()) {
        this.errorMsg = "Please check all fields!";
      } else {
        let packageId = vm.packageId;
        let formData = new FormData();
        formData.append("file", this.file);
        this.$store
          .dispatch(`proxy/${IMPORT_PROXY_FILE}`, {
            formData,
            packageId,
          })
          .then(
            function () {
              vm.close();
              EventBus.$emit(NEW_IMPORTED_PROXIES);
            },
            function (error) {
              vm.errorMsg = error;
            }
          )
          .catch(() => {
            this.errorMsg = "Something went wrong";
          });
      }
    },
  },
};
</script>
